<template>
  <div
    v-if="isLoading"
    style="display: flex; height: 100vh; align-items: center"
  >
    <v-progress-circular
      :size="70"
      :width="7"
      color="accent"
      indeterminate
      style="width: 100%; margin: 0 auto"
    ></v-progress-circular>
  </div>
  <div v-else>
    <v-card>
      <v-card-title>Notite</v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-timeline dense clipped>
          <v-timeline-item
            v-for="(note, index) in notes"
            :key="index"
            class="mb-4"
            color="primary"
            small
          >
            <v-card class="elevation-2">
              <v-card-title class="caption font-weight-light grey--text">
                {{ $moment(note.date).format("DD-MM-YYYY HH:mm") }}
              </v-card-title>
              <v-card-text class="body-1 primary--text">
                {{ note.message }}
              </v-card-text>
            </v-card>
          </v-timeline-item>
        </v-timeline>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
export default {
  name: "Results",
  data() {
    return {
      notes: [],
      isLoading: false,
    };
  },
  async created() {
    await this.loadNotes();
  },
  computed: {
    ...mapState("authentication", {
      auth_token: "auth_token",
      user: "user",
    }),
  },
  methods: {
    async loadNotes() {
      this.isLoading = true;
      try {
        let response = await axios.get(
          `${process.env.VUE_APP_API_URL}/api/users/${this.user.id}/notes`,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        this.notes = response.data;
      } catch (_) {
        this.isLoading = false;
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>